import { useQuery } from "@tanstack/vue-query";
import { ReadBranchNowResult, ReadEmployeeNowProp, ReadEmployeeNowResult } from "@/types/Almighty";
import { readBranchNow, readEmployeeNow } from "@/query/apis/almighty";
import { AlmightyReadEmployeeNowKey, AlmightyReadBranchNowKey } from "@/query/keys";

export const useEmployeeNow = (data: ReadEmployeeNowProp) =>
  useQuery<ReadEmployeeNowResult>([AlmightyReadEmployeeNowKey, data.branch_id], () =>
    readEmployeeNow(data),
  );
export const useBranchNow = () =>
  useQuery<ReadBranchNowResult>([AlmightyReadBranchNowKey], readBranchNow);
