import { ReadEmployeeNowProp } from "@/types/Almighty";
import { request } from "@/query/request";

export const readEmployeeNow = async (data: ReadEmployeeNowProp) =>
  await request({
    url: `almighty_new/employee_now/${data.branch_id}`,
    method: "get",
  });
export const readBranchNow = async () =>
  await request({
    url: "almighty_new/branch_now",
    method: "get",
  });
