import { ReadEmployeeNowProp } from "@/types/Almighty";
import { request } from "@/query/request";
import { ExecBundleChangeProp } from "@/types/BundleChange";

export const execBundleChange = async (data: ExecBundleChangeProp) =>
  await request({
    url: `bundle_change/employee`,
    method: "patch",
    data,
  });
